<template>
  <div class="handel-channel">
    <n-modal
        title="编辑频道"
        v-model:show="showModal"
        preset="card"
        style="width: 570px"
        :header-style="{padding:'17px 13px 0 33px'}"
    >
      <div class="modal-content">
        <!--        我的频道-->
        <div class="my-channel">
          <div class="head">
            <p class="title">
              我的频道
              <span>点击编辑频道列表</span>
            </p>
            <span class="edit-btn" @click="handelSave">{{ showSave ? '保存' : '编辑' }}</span>
          </div>
          <ul class="my-channel-box" v-if="myChannelList?.length">
            <li
                class="my-channel-item"
                v-for="(item,index) in myChannelList"
                :key="item.id"
                :class="item.isDefault?'enabel-box':''"
                @click="changeMyChannelItem(item)"
            >
              {{ item.title }}
              <img src="@/assets/images/pc_home_jian.svg" alt="" class="jian-icon" v-if="!item.isDefault && showSave">
            </li>
          </ul>
        </div>
        <!--        全部频道-->
        <div class="all-channel">
          <p class="title">
            所有频道
            <span>点击编辑频道列表</span>
          </p>
          <ul class="all-channel-box" v-if="allChannelList?.length">
            <li
                class="all-channel-item"
                v-for="(item,index) in allChannelList"
                :key="item.id"
                @click="changeAllChannelItem(item)"
            >
              {{ item.title }}
              <img src="@/assets/images/pc_home_jia.svg" class="jia-icon" v-if="showSave">
            </li>
          </ul>
        </div>
      </div>
    </n-modal>
  </div>
</template>

<script setup>
import {ChannelApi} from "@/apis/api";
import { useMessage } from "naive-ui";
const message = useMessage()
let channelApi = new ChannelApi();
const props = defineProps({
  activeTab: {
    type: Number,
    default: () => 0
  },
})
const resetData = inject('resetData')
const myChannelList = ref([])//我的频道列表数据
const allChannelList = ref([])//全部频道列表数据
const showModal = ref(false)
const showSave = ref(false)
const openModal = () => {
  showModal.value = true
}
const getMyChannelList = async () => {
  let res = await channelApi.apiAppChannelGetChannelByIdPost(
      {searchType: props.activeTab + 1},
  )
  myChannelList.value = res.data;
}
const getAllChannelList = async () => {
  let res = await channelApi.apiAppChannelGetAppHomeByTypePost(
      {searchType: props.activeTab + 1},
  )
  allChannelList.value = res.data
}
const changeMyChannelItem = (data) => {
  if (!data.isDefault && showSave.value){
    allChannelList.value.push(data)
    myChannelList.value = myChannelList.value.filter((item) => {
      return item.id !== data.id
    })
  }
}
const changeAllChannelItem = (data) => {
  if (showSave.value){
    myChannelList.value.push(data)
    allChannelList.value = allChannelList.value.filter((item) => {
      return item.id !== data.id
    })
  }
}

// 保存
const handelSave = () => {
  showSave.value = !showSave.value
  if (!showSave.value){
    //点击保存
    let arr = [];
    myChannelList.value.forEach(item => {
        arr.push(item.id)
    })
    let params = arr.join(',')
    // console.log(typeof params);
    channelApi.apiAppChannelEditOwnChannelPost({
      searchType:Number(props.activeTab + 1),
      ids:params
    }).then(res=>{
      // console.log(res);
      showModal.value = false
      resetData()
    })
        .catch(err=>{
          message.error('保存失败')
        })

  }
}

defineExpose({
  openModal,
  getMyChannelList,
  getAllChannelList
})
</script>

<style lang="less" scoped>
.enabel-box {
  background-color: #F7F7F7;
}

.modal-content {
  padding: 30px 12px 36px 6px;

  .my-channel {
    .head {
    @apply flex justify-between;

      .title {
        font-weight: 500;
        font-size: 16px;

        span {
          color: #999999;
          font-size: 14px;
          margin-left: 13px;
        }
      }

      .edit-btn, .save-btn {
      @apply font-medium cursor-pointer;
        font-size: 16px;
        color: #FFAD28;
      }
    }

    .my-channel-box {
    @apply flex flex-wrap;
      margin-top: 23px;

      .my-channel-item {
        padding: 8px 22px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #DFDFDF;
        color: #000000;
        font-size: 16px;
        margin: 0 24px 24px 0;
        cursor: pointer;
        position: relative;

        .jian-icon {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

  }

  .all-channel {
    margin-top: 26px;

    .title {
      font-weight: 500;
      font-size: 16px;

      span {
        color: #999999;
        font-size: 14px;
        margin-left: 13px;
      }
    }

    .all-channel-box {
    @apply flex flex-wrap;
      margin-top: 23px;

      .all-channel-item {
        padding: 8px 22px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #DFDFDF;
        color: #000000;
        font-size: 16px;
        margin-right: 24px;
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: #F7F7F7;
        }

        .jia-icon {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
<style>
.n-card-header__close {
  margin: -12px 0 0 8px !important;
}

.n-card-header__main {
  @apply text-center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6) !important;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 13px;
}
</style>